<template>
  <b-form-group
    label="Cinsiyet"
    label-for="gender"
  >
    <validation-provider
      #default="{ errors }"
      name="Cinsiyet"
      rules="required"
    >
      <v-select
        id="gender"
        v-model="customer.id_com_gender"
        label="title"
        :reduce="gender => gender.id"
        :options="genders"
        placeholder="Cinsiyet"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Gender',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    genders() {
      return this.$store.getters['genders/getGenders']
    },
  },
  created() {
    localize(this.locale)
    this.getGenders()
  },
  methods: {
    getGenders() {
      this.$store.dispatch('genders/gendersList', {
        select: ['com_gender.id AS id', 'com_gender.title AS title'],
      })
    },
  },
}
</script>

<style scoped></style>
