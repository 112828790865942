<template>
  <b-form-group
    label="Vergi Dairesi"
    label-for="tax_admin"
  >
    <b-form-input
      id="tax_admin"
      v-model="customer.tax_admin"
      placeholder="Vergi Dairesi"
      :readonly="isReadonly"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'NameSurname',
  components: {
    BFormGroup,
    BFormInput,
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
}
</script>

<style scoped></style>
