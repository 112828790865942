<template>
  <b-form-group
    label="Meslek"
    label-for="jobs"
  >
    <validation-provider
      #default="{ errors }"
      name="Meslek"
      rules="required"
    >
      <v-select
        id="jobs"
        v-model="customer.id_com_job"
        label="title"
        :reduce="job => job.id"
        :options="jobs"
        placeholder="Meslek"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Job',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    jobs() {
      return this.$store.getters['jobs/getJobs']
    },
  },
  created() {
    this.getJobs()
    localize('tr')
  },
  methods: {
    getJobs() {
      this.$store.dispatch('jobs/jobsList', {
        select: ['com_job.id AS id', 'com_job.title AS title'],
      })
    },
  },
}
</script>
